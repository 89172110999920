import React from 'react'
import { Link } from 'gatsby'
import 'bootstrap/dist/css/bootstrap.min.css'
import Container from '../container'
import get from 'lodash/get'
import Wrapper from '../wrapper/wrapper'
import SideBar from '../Side-bar/side-bar.board.js'
import Header from '../Header/noLangHeader'

import Footer from '../Footer/footer'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import footerStyle from '../Footer/footer.module.css'
import Helmet from 'react-helmet'
import Navbar from '../Navbar/navbar-board'
import '../Content/content.css'
import Menu from '../Board-Menu/menu'
import '../Mobile-menu/mobile.menu.css'
import { GlobalStyle } from '../../utils/global'
import Column4BoardTable from '../BoardsTable/4Column_boardTable'
import Column7BoardTable from '../BoardsTable/7Column_boardTable'
import Column6BoardTable from '../BoardsTable/6Column_boardTable'
import MLSChartA from '../Chart/mls.chartA'
import MLSChartB from '../Chart/mls.chartB'
import MLSChartC from '../Chart/mls.chartC'
import MLSChartD from '../Chart/mls.chartD'
import MobileTab from '../Mobile-Tab/mobile-tab'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'

const customRow = {
  background: '#edf0fe',
}
const addStyle = {
  padding: '5px;',
  textAlign: 'left',
}
class MTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.children = this.props.children
  }
  getYear() {
    return new Date().getFullYear()
  }
  topFunction() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handeleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handeleScroll)
  }
  handeleScroll() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById('myBtn').style.display = 'block'
    } else {
      document.getElementById('myBtn').style.display = 'none'
    }
  }
  render() {
    const mls = this.props.mls
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')

    const { mlsChart = {} } = this.props
    const { mlsChartB = {} } = this.props
    const { mlsChartC = {} } = this.props
    const { mlsChartD = {} } = this.props

    const possibleSlugsMls = [
      'Residential Activity',
      'Sales by Category',
      'Sales by Price Range',
      'Market Conditions',
      'Median Price',
    ]
    const actualSlugsMls = mls
      ? mls.mlsStatistics
        ? mls.mlsStatistics.map(a => a.MlsboardPageName)
        : []
      : []
    let intersectionMls = possibleSlugsMls
      .filter(x => !actualSlugsMls.includes(x))
      .concat(actualSlugsMls.filter(x => !possibleSlugsMls.includes(x)))

    /*    const { location, children } = this.props
    let header

    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    } */

    return (
      <Container>
        <GlobalStyle />
        <Header />

        <Navbar />
        <Wrapper>
          {/* <Breadcrumb title={this.props.board ? mls.boardName : ''} /> */}
          <div style={{ paddingTop: '55px' }}></div>
          <Button
            onClick={this.topFunction}
            id="myBtn"
            title="Go to top"
            className="scroll-btn"
          >
            <img
              src={require('../../images/btn_backtop.png')}
              alt="Scroll to top"
              title="Scroll to top"
            />
          </Button>

          {this.children}

          {this.props.board ? (
            <div className="content-wrapper">
              <div className="row split">
                <div className="col-md">
                  <div className="entry-header">
                    <Helmet title={`${mls.title} | ${siteTitle}`} />

                    <div>
                      <div className="container">
                        <div className="row" style={customRow}>
                          <div className=".col-lg-"></div>
                        </div>
                      </div>
                    </div>

                    <h1
                      className="entry-title"
                      dangerouslySetInnerHTML={{
                        __html: mls.boardName,
                      }}
                    />
                    <h2
                      dangerouslySetInnerHTML={{
                        __html: mls.MlsboardPageName,
                      }}
                    />
                  </div>

                  <div className="entry-content">
                    <div className="entry-sub">
                      <h2
                        className="entry-headline"
                        dangerouslySetInnerHTML={{
                          __html: mls.headline,
                        }}
                      />
                      <div className="content">
                        {/* <!--  ==========================================================  -->
        <!-- START--- MAIN CONTENT 1  ==========================  -->
        <!--  ==========================================================  --> */}

                        <div className="mainFloatContainer">
                          <br />
                          <div className="LeftFloat hideOnMobile">
                            {mlsChart && mlsChart.length ? (
                              <MLSChartA
                                mlsChart={mlsChart}
                                name={this.props.name1}
                              />
                            ) : null}

                            {/* {this.props.chatRaChart.length > 0 ? (
                              <ChartY
                                chatChart={this.props.chatRaChart}
                                name={this.props.name1}
                              />
                            ) : null}
                            <br />
                          </div>
                          <div className="LeftFloat showOnMobile">
                            {this.props.chatRaChart.length > 0 ? (
                              <ChartY
                                chatChart={this.props.chatRaChart}
                                name={this.props.name1}
                              />
                            ) : null} */}
                          </div>
                          <div className="RightFloat">
                            {mls.mainContent !== null && (
                              <div
                                className="content"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    mls.mainContent.childContentfulRichText
                                      .html,
                                }}
                              />
                            )}
                          </div>

                          {/* <!--  ==========================================================  -->
        <!-- END--- MAIN CONTENT 1  ==========================  -->
        <!--  ==========================================================  --> */}

                          {/* <!--  ==========================================================  -->
        <!-- START--- TABLES 1  ==========================  -->
        <!--  ==========================================================  --> */}

                          {this.props.fourColTableData.length > 0 ? (
                            <Column4BoardTable
                              name={this.props.fourColTableName}
                              tableData={this.props.fourColTableData}
                            />
                          ) : null}
                          {this.props.sevenColTableData.length > 0 ? (
                            <Column7BoardTable
                              name={this.props.sevenColTableName}
                              tableData={this.props.sevenColTableData}
                            />
                          ) : null}
                          {this.props.sixColTableData.length > 0 ? (
                            <Column6BoardTable
                              name={this.props.sixColTableName}
                              tableData={this.props.sixColTableData}
                            />
                          ) : null}

                          {/* <!--  ==========================================================  -->
        <!-- END--- TABLES 1  ==========================  -->
        <!--  ==========================================================  --> */}

                          {/* <!--  ==========================================================  -->
        <!-- START--- MAIN CONTENT 2  ==========================  -->
        <!--  ==========================================================  --> */}
                        </div>

                        <div className="mainFloatContainer">
                          <br />
                          <div className="LeftFloat hideOnMobile">
                            {mlsChartB && mlsChartB.length ? (
                              <MLSChartB
                                mlsChartB={mlsChartB}
                                name={this.props.name1}
                              />
                            ) : null}
                            {/* {this.props.chatRaChart2.length > 0 ? (
                              <ChartY
                                chatChart={this.props.chatRaChart2}
                                name={this.props.name2}
                              />
                            ) : null} */}
                          </div>
                          <div className="LeftFloat showOnMobile">
                            {/* {this.props.chatRaChart2.length > 0 ? (
                              <ChartY
                                chatChart={this.props.chatRaChart2}
                                name={this.props.name2}
                              />
                            ) : null} */}
                          </div>
                          <div className="RightFloat">
                            {mls.mainContent2 !== null && (
                              <div
                                className="content"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    mls.mainContent2.childContentfulRichText
                                      .html,
                                }}
                              />
                            )}
                          </div>
                          {/* <!--  ==========================================================  -->
        <!-- END--- MAIN CONTENT 2  ==========================  -->
        <!--  ==========================================================  --> */}

                          {/* <!--  ==========================================================  -->
        <!-- START--- TABLES 2  ==========================  -->
        <!--  ==========================================================  --> */}

                          {this.props.fourColTableData2.length > 0 ? (
                            <Column4BoardTable
                              name={this.props.fourColTableName2}
                              tableData={this.props.fourColTableData2}
                            />
                          ) : null}
                          {this.props.sevenColTableData2.length > 0 ? (
                            <Column7BoardTable
                              name={this.props.sevenColTableName2}
                              tableData={this.props.sevenColTableData2}
                            />
                          ) : null}

                          {this.props.sixColTableData2.length > 0 ? (
                            <Column6BoardTable
                              name={this.props.sixColTableName2}
                              tableData={this.props.sixColTableData2}
                            />
                          ) : null}

                          {/* <!--  ==========================================================  -->
        <!--  END--- TABLES 2 ==========================  -->
        <!--  ==========================================================  --> */}

                          {/* <!--  ==========================================================  -->
        <!-- START--- MAIN CONTENT 3  ==========================  -->
        <!--  ==========================================================  --> */}
                        </div>

                        <div className="mainFloatContainer">
                          <br />
                          <div className="LeftFloat hideOnMobile">
                            <br />
                            {mlsChartC && mlsChartC.length ? (
                              <MLSChartC
                                mlsChartC={mlsChartC}
                                name={this.props.name1}
                              />
                            ) : null}
                            {/* {this.props.chatRaChart3.length > 0 ? (
                              <ChartY chatChart={this.props.chatRaChart3} />
                            ) : null}
                          </div>
                          <div className="LeftFloat showOnMobile">
                            {this.props.chatRaChart3.length > 0 ? (
                              <ChartY chatChart={this.props.chatRaChart3} />
                            ) : null} */}
                          </div>
                          <div className="RightFloat">
                            {mls.mainContent3 !== null && (
                              <div
                                className="content"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    mls.mainContent3.childContentfulRichText
                                      .html,
                                }}
                              />
                            )}
                          </div>
                          {/* <!--  ==========================================================  -->
        <!--  END--- MAIN CONTENT 3 ==========================  -->
        <!--  ==========================================================  --> */}

                          {/* <!--  ==========================================================  -->
        <!-- START---  TABLES 3 ==========================  -->
        <!--  ==========================================================  --> */}

                          {this.props.sixColTableData3.length > 0 ? (
                            <Column6BoardTable
                              name={this.props.sixColTableName3}
                              tableData={this.props.sixColTableData3}
                            />
                          ) : null}
                          {this.props.sevenColTableData3.length > 0 ? (
                            <Column7BoardTable
                              name={this.props.sevenColTableName3}
                              tableData={this.props.sevenColTableData3}
                            />
                          ) : null}

                          {/* <!--  ==========================================================  -->
        <!-- START--- MAIN CONTENT 4  ==========================  -->
        <!--  ==========================================================  --> */}
                        </div>

                        <div className="mainFloatContainer">
                          <br />
                          <div className="LeftFloat hideOnMobile">
                            {mlsChartD && mlsChartD.length ? (
                              <MLSChartD
                                mlsChartD={mlsChartD}
                                name={this.props.name1}
                              />
                            ) : null}
                            {/*      {this.props.chatRaChart4.length > 0 ? (
                              <ChartY chatChart={this.props.chatRaChart4} />
                            ) : null}
                          </div>
                          <div className="LeftFloat showOnMobile">
                            {this.props.chatRaChart4.length > 0 ? (
                              <ChartY chatChart={this.props.chatRaChart4} />
                            ) : null} */}
                          </div>
                          <div className="RightFloat">
                            {mls.mainContent4 !== null && (
                              <div
                                className="content"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    mls.mainContent4.childContentfulRichText
                                      .html,
                                }}
                              />
                            )}
                          </div>
                          {/* <!--  ==========================================================  -->
        <!-- END--- MAIN CONTENT 4  ==========================  -->
        <!--  ==========================================================  --> */}

                          {/* <!--  ==========================================================  -->
        <!-- START--- TABLES 4  ==========================  -->
        <!--  ==========================================================  --> */}

                          {this.props.sixColTableData4.length > 0 ? (
                            <Column6BoardTable
                              name={this.props.sixColTableName4}
                              tableData={this.props.sixColTableData4}
                            />
                          ) : null}
                          {this.props.sevenColTableData4.length > 0 ? (
                            <Column7BoardTable
                              name={this.props.sevenColTableName4}
                              tableData={this.props.sevenColTableData4}
                            />
                          ) : null}
                          {/* <!--  ==========================================================  -->
        <!-- END--- TABLES 4  ==========================  -->
        <!--  ==========================================================  --> */}

                          {/* <!--  ==========================================================  -->
        <!--  START--- ADDITIONAL CONTENT ==========================  -->
        <!--  ==========================================================  --> */}

                          {mls.additionalContent !== null && (
                            <div
                              style={addStyle}
                              dangerouslySetInnerHTML={{
                                __html:
                                  mls.additionalContent.childMarkdownRemark
                                    .html,
                              }}
                            />
                          )}
                          {/* <!--  ==========================================================  -->
        <!--  END--- ADDITIONAL CONTENT  ==========================  -->
        <!--  ==========================================================  --> */}
                        </div>

                        {/* <!--  ==========================================================  -->
        <!--  BOARD AND CREA DECLARATION HERE ==========================  -->
        <!--  ==========================================================  --> */}
                        <div className={footerStyle.boardCredit}>
                          <hr />
                          <p>
                            The information contained in this report has been
                            prepared by The Canadian Real Estate Association, in
                            co-operation with the {mls.boardName}. The
                            information has been drawn from sources deemed to be
                            reliable, but the accuracy and completeness of the
                            information is not guaranteed. In providing this
                            information, neither The Canadian Real Estate
                            Association nor the {mls.boardName}
                            assumes any responsibility or liability.
                            Copyright&nbsp;&copy;{this.getYear()}. The Canadian
                            Real Estate Association. All rights reserved.
                            Reproduction in whole or in part is prohibited
                            without written permission.
                          </p>
                        </div>
                        {/* <!--  ==========================================================  -->
        <!-- END EDITING PAGE CONTENT HERE =============================  -->
        <!--  ==========================================================  --></div> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-sm- side-content mobile-style">
                  <hr />
                  <ThemeToggler>
                    {({ theme, toggleTheme, isOn, handleToggle }) => (
                      <>
                        <input
                          checked={theme === 'dark'}
                          onChange={e =>
                            toggleTheme(e.target.checked ? 'dark' : 'light')
                          }
                          className="react-switch-checkbox"
                          id={`react-switch-new`}
                          type="checkbox"
                        />
                        Dark Mode
                        <label
                          style={{ background: isOn && onColor }}
                          className="react-switch-label"
                          htmlFor={`react-switch-new`}
                        >
                          <span className={`react-switch-button`}>
                            <div className="sun_icon" />
                          </span>
                        </label>
                      </>
                    )}
                  </ThemeToggler>
                  <hr />
                  <div className="boardInformation sidebar-wrapper">
                    <h5 className="text_upper">National Statistics</h5>
                    <ul>
                      <li>
                        <Link
                          activeStyle={{ color: '#345fa8', fontWeight: 'bold' }}
                          to={'/en-CA'}
                        >
                          National English
                        </Link>
                      </li>
                      <li>
                        <Link
                          activeStyle={{ color: '#345fa8', fontWeight: 'bold' }}
                          to="/fr-CA"
                        >
                          National Fran&ccedil;ais
                        </Link>
                      </li>
                      <li>
                        <br />
                        <Menu />
                        <br />
                      </li>
                    </ul>
                    {/* Menu for sub references to other boards */}
                    {mls.menuCompile != null && mls.menuCompile.boardsSubRef && (
                      <ul>
                        <h5 className="text_upper">Board regions</h5>
                        {mls.menuCompile.boardsSubRef.map((item, i) => (
                          <li key={i}>
                            <Link
                              to={`/board/${item.slug}`}
                              activeStyle={{
                                color: '#9E1D30',
                                fontWeight: 'bold',
                              }}
                            >
                              {item.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                    {/* Menu for board sub pages */}
                    {mls.menuCompile != null && (
                      <ul>
                        <h5 className="text_upper">Board Information</h5>
                        {mls.menuCompile.boardSubMenu.map((item, i) => (
                          <li key={i}>
                            <Link
                              to={`/board/${item.slug}`}
                              activeStyle={{
                                color: '#9E1D30',
                                fontWeight: 'bold',
                              }}
                            >
                              {item.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                    {/* menu for MLS sub pages */}
                    <div className="sidebar-wrapper">
                      {mls.menuCompile != null && mls.menuCompile.boardMlsMenu && (
                        <ul>
                          <h5 className="text_upper">MLS Statistics</h5>
                          {mls.menuCompile.boardMlsMenu.map((item, i) => (
                            <li key={i}>
                              <Link
                                to={`/mls/${item.slug}`}
                                activeStyle={{
                                  color: '#9E1D30',
                                  fontWeight: 'bold',
                                }}
                              >
                                {item.title}
                              </Link>
                            </li>
                          ))}{' '}
                        </ul>
                      )}
                      {/*         {mls.mlsSubRefernces !== null && (
                        <ul>
                          <h5>
                            <li className="text_upper_link">
                              {intersectionMls.length === 2
                                ? intersectionMls[0]
                                : mls.MlsboardPageName}
                            </li>
                          </h5>
                          {mls.mlsSubRefernces.map((item, i) => (
                            <li key={i}>
                              <Link to={`/mls/${item.slug}`}>
                                {item.MlsboardPageName}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )} */}
                    </div>
                    {/* mls ends */}
                    <SideBar />
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <Footer />
        </Wrapper>
        {/*     <MobileTab /> */}
      </Container>
    )
  }
}

export default MTemplate
