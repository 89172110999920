import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'
import Helmet from 'react-helmet'
import * as PropTypes from 'prop-types'
import get from 'lodash/get'
import Layout from '../components/Layouts/layoutmls.js'

import { GlobalStyle } from '../utils/global'

const propTypes = {
  data: PropTypes.object.isRequired,
}

class MlsTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      data2: [],
      data3: [],
      data4: [],

      fourColTableData: [],
      fourColTableData2: [],
      fourColTableName2: '',

      fourColTableName: '',
      sevenColTableName: '',
      sevenColTableData: [],
      sevenColTableName2: '',
      sevenColTableData2: [],
      sevenColTableName3: '',
      sevenColTableData3: [],
      sevenColTableName4: '',
      sevenColTableData4: [],
      sixColTableName: '',
      sixColTableData: [],
      sixColTableName2: '',
      sixColTableData2: [],
      sixColTableName3: '',
      sixColTableData3: [],
      sixColTableName4: '',
      sixColTableData4: [],
      mlsChart: [],
      mlsChartB: [],
      mlsChartC: [],
      mlsChartD: [],
    }
  }
  getYear() {
    return new Date().getFullYear()
  }
  componentDidMount() {
    const slug = this.props.pageContext.slug
    if (slug == 'barr-residential-activity') {
      this.setState({
        fourColTableData: this.props.data.BarrRA.edges,
        fourColTableData2: this.props.data.BarrRA2.edges,
        fourColTableName: 'Summary - Sales by Housing Type',
        fourColTableName2: 'Summary – Median Price by Housing Type',
      })
    } else if (slug == 'barr-sales-by-price-range') {
      this.setState({
        sixColTableData: this.props.data.BarrSPR.edges,
        sixColTableData2: this.props.data.BarrSPR2.edges,
        sixColTableData3: this.props.data.BarrSPR3.edges,
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableName2:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableName3:
          'Summary – Sales by Price Range / Year-over-year percentage change',
      })
    } else if (slug == 'barr-sales-by-category') {
      this.setState({})
    } else if (slug == 'barr-market-conditions') {
      this.setState({})
    } else if (slug == 'barr-median-price') {
      this.setState({})
    } else if (slug == 'chat-residential-activity') {
      this.setState({})
    } else if (slug == 'chat-sales-by-price-range') {
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.ChatSPR.edges,
      })
    } else if (slug == 'chat-market-conditions') {
      this.setState({})
    } else if (slug == 'chat-median-price') {
      this.setState({})
    } else if (slug == 'chat-sales-by-category') {
      this.setState({})
    } else if (slug == 'musk-residential-activity') {
      this.setState({
        fourColTableName: 'Summary – Sales by Housing Type',
        fourColTableData: this.props.data.MuskRA1.edges,
        fourColTableName2: 'Summary – Median Price by Housing Type',
        fourColTableData2: this.props.data.MuskRA2.edges,
        sevenColTableName3:
          'Detailed – Non-Waterfront Residential Sales and Median Price by Area',
        sevenColTableData3: this.props.data.MuskRA3.edges,
        sevenColTableName4:
          'Detailed – Non-Waterfront Residential Sales and Median Price by Area',
        sevenColTableData4: this.props.data.MuskRA4.edges,
      })
    } else if (slug == 'musk-sales-by-price-range') {
      this.setState({
        sixColTableData: this.props.data.MuskSPR.edges,
        sixColTableData2: this.props.data.MuskSPR1.edges,
        sixColTableName: 'Summary – Sales by Price Range ',
        sixColTableName2: 'Year-over-year percentage change',
      })
    } else if (slug == 'musk-sales-by-category') {
      this.setState({})
    } else if (slug == 'musk-median-price') {
      this.setState({})
    } else if (slug == 'musk-market-conditions') {
      this.setState({})
    } else if (slug == 'sra-residential-activity') {
      this.setState({
        fourColTableData: this.props.data.SraRA.edges,
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData2: this.props.data.SraRA2.edges,
        fourColTableName2: 'Summary – Sales and Median Price / Single Detached',
      })
    } else if (slug == 'sra-sales-by-price-range') {
      this.setState({
        sixColTableData: this.props.data.SraSPR.edges,
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData2: this.props.data.SraSPR2.edges,
        sixColTableName2:
          'Summary – Sales by Price Range / Year-over-year percentage change',
      })
    } else if (slug == 'edmo-residential-activity') {
      this.setState({
        fourColTableData: this.props.data.EdmoRA.edges,
        fourColTableData2: this.props.data.EdmoRA2.edges,
        fourColTableName: 'Summary – Sales by Housing Type',
        fourColTableName2: 'Summary – Median Price by Housing Type',
      })
    } else if (slug == 'edmo-sales-by-price-range') {
      this.setState({
        sixColTableData: this.props.data.EdmoSPR.edges,
        sixColTableData2: this.props.data.EdmoSPR2.edges,
        sixColTableData3: this.props.data.EdmoSPR3.edges,

        sixColTableName: 'Summary – Sales by Price Range ',
        sixColTableName2: 'Year-over-year percentage change',
        sixColTableName3: 'Year-over-year percentage change',
      })
    } else if (slug == 'edmo-sales-by-category') {
      this.setState({})
    } else if (slug == 'edmo-median-price') {
      this.setState({})
    } else if (slug == 'edmo-market-conditions') {
      this.setState({})
    } else if (slug == 'brnd-residential-activity') {
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.BrndRA1.edges,
      })
    } else if (slug == 'brnd-sales-by-price-range') {
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.BrndSP1.edges,
      })
    } else if (slug == 'brnd-sales-by-category') {
      this.setState({})
    } else if (slug == 'brnd-median-price') {
      this.setState({})
    } else if (slug == 'brnd-market-conditions') {
      this.setState({})
    } else if (slug == 'treb-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales by Housing Type',
        fourColTableData: this.props.data.TrebRA.edges,
        fourColTableName2: 'Summary – Median Price by Housing Type',
        fourColTableData2: this.props.data.TrebRA2.edges,
      })
    } else if (slug == 'treb-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableData: this.props.data.TrebSPR.edges,
        sixColTableData2: this.props.data.TrebSPR2.edges,
        sixColTableData3: this.props.data.TrebSPR3.edges,
        sixColTableData4: this.props.data.TrebSPR4.edges,

        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableName2:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableName3:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableName4:
          'Summary – Sales by Price Range / Year-over-year percentage change',

        /* name1:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        name2: 'Summary – Sales by Price Range ', */
      })
    } else if (slug == 'treb-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'treb-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'treb-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'brnt-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableData: this.props.data.BrntRA1.edges,
        fourColTableData2: this.props.data.BrntRA2.edges,
        fourColTableName: 'Summary – Sales by Housing Type',
        fourColTableName2: 'Summary – Median Price by Housing Type',
      })
    } else if (slug == 'brnt-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableData: this.props.data.BrntSP1.edges,
        sixColTableData2: this.props.data.BrntSP2.edges,
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableName2:
          'Summary – Sales by Price Range / Year-over-year percentage change',
      })
    } else if (slug == 'brnt-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'brnt-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'brnt-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'broo-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.BrooRA1.edges,
      })
    } else if (slug == 'broo-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.BrooSP1.edges,
      })
    } else if (slug == 'broo-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'broo-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'broo-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'cari-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.CariRA1.edges,
        sevenColTableName2: 'Detailed – Single Detached Sales and Median Price by Area / Single Detached',
        sevenColTableData2: this.props.data.CariRA2.edges,
      })
    } else if (slug == 'cari-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.CariSP1.edges,
      })
    } else if (slug == 'cari-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'cari-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'cari-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'camb-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales by Housing Type',
        fourColTableData: this.props.data.CambRA1.edges,
        fourColTableName2: 'Summary – Median Price by Housing Type',
        fourColTableData2: this.props.data.CambRA2.edges,
      })
    } else if (slug == 'camb-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'camb-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range  Year-over-year percentage change',
        sixColTableData: this.props.data.CambSP1.edges,
        sixColTableName2:
          'Summary – Sales by Price Range  Year-over-year percentage change',
        sixColTableData2: this.props.data.CambSP2.edges,
      })
    } else if (slug == 'camb-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'camb-median-price') {
      // No table at home page
      this.setState({})

      /*} else if (slug == 'coll-residential-activity') {
        // No table at home page
        this.setState({
          sevenColTableName:
            'Western District Summary - Sales and Median Price by Category',
          sevenColTableData: this.props.data.CollRA1.edges,
          sevenColTableName2:
            'Eastern District Summary - Sales and Median Price by Category',
          sevenColTableData2: this.props.data.CollRA2.edges,
          sevenColTableName3:
            'Western District Detailed - Detached Sales and Median Price by Area',
          sevenColTableData3: this.props.data.CollRA3.edges,
          sevenColTableName4:
            'Eastern District Detailed - Detached Sales and Median Price by Area',
          sevenColTableData4: this.props.data.CollRA4.edges,
        })
      } else if (slug == 'coll-sales-by-category') {
        // No table at home page
        this.setState({
          name1: 'WESTERN DISTRICT',
          name2: 'EASTERN DISTRICT',
        })
      } else if (slug == 'coll-sales-by-price-range') {
        // No table at home page
        this.setState({
          sixColTableName:
            'Summary – Sales by Price Range / Year-over-year percentage change',
          sixColTableData: this.props.data.CollSPR.edges,
  
          sixColTableName2:
            'Summary – Sales by Price Range / Year-over-year percentage change',
          sixColTableData2: this.props.data.CollSPR2.edges,
  
          /* name1: 'WESTERN DISTRICT',
          name2: 'EASTERN DISTRICT', 
        })
      } else if (slug == 'coll-market-conditions') {
        // No table at home page
        this.setState({})
      } else if (slug == 'coll-median-price') {
        // No table at home page
        this.setState({})*/
    } else if (slug == 'chil-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales by Housing Type',
        fourColTableData: this.props.data.ChilRA1.edges,
        fourColTableName2: 'Summary – Median Price by Housing Type',
        fourColTableData2: this.props.data.ChilRA2.edges,
      })
    } else if (slug == 'chil-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.ChilSP1.edges,
        sixColTableName2:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData2: this.props.data.ChilSP2.edges,
        sixColTableName3:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData3: this.props.data.ChilSP3.edges,
      })
    } else if (slug == 'chil-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'chil-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'chil-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'hami-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales by Housing Type',
        fourColTableData: this.props.data.HamiRA1.edges,
        fourColTableName2: 'Summary – Median Price by Housing Type',
        fourColTableData2: this.props.data.HamiRA2.edges,
      })
    } else if (slug == 'hami-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableData: this.props.data.HamiSP1.edges,
        sixColTableData2: this.props.data.HamiSP2.edges,
        sixColTableData3: this.props.data.HamiSP3.edges,
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',

        sixColTableName2:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableName3:
          'Summary – Sales by Price Range / Year-over-year percentage change',
      })
    } else if (slug == 'hami-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'hami-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'hami-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'huro-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.HuroRA1.edges,
      })
    } else if (slug == 'huro-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.HuroSP1.edges,
      })
    } else if (slug == 'huro-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'huro-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'huro-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'king-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.KingRA1.edges,
      })
    } else if (slug == 'king-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.KingSP1.edges,
      })
    } else if (slug == 'king-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'king-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'king-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'leth-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.LethRA1.edges,
      })
    } else if (slug == 'leth-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.LethSP1.edges,
      })
    } else if (slug == 'leth-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'leth-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'leth-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'medi-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.MediRA1.edges,
      })
    } else if (slug == 'medi-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.MediSP1.edges,
      })
    } else if (slug == 'medi-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'medi-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'medi-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'stca-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableData: this.props.data.StcaRA1.edges,
        fourColTableData2: this.props.data.StcaRA2.edges,
        fourColTableName: 'Summary - Sales by Housing Type',
        fourColTableName2: 'Summary – Median Price by Housing Type',
      })
    } else if (slug == 'stca-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableData: this.props.data.StcaSP1.edges,
        sixColTableData2: this.props.data.StcaSP2.edges,
        sixColTableData3: this.props.data.StcaSP3.edges,
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableName2:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableName3:
          'Summary – Sales by Price Range / Year-over-year percentage change',
      })
    } else if (slug == 'stca-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'stca-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'stca-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'miss-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales by Housing Type',
        fourColTableData: this.props.data.MissRA1.edges,
        fourColTableName2: 'Summary – Median Price by Housing Type',
        fourColTableData2: this.props.data.MissRA2.edges,
      })
    } else if (slug == 'miss-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.MissSP1.edges,
        sixColTableName2:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData2: this.props.data.MissSP2.edges,
        sixColTableName3:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData3: this.props.data.MissSP3.edges,
        sixColTableName4:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData4: this.props.data.MissSP4.edges,
      })
    } else if (slug == 'miss-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'miss-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'miss-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'otta-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales by Housing Type',
        fourColTableData: this.props.data.OttaRA1.edges,
        fourColTableName2: 'Summary – Median Price by Housing Type',
        fourColTableData2: this.props.data.OttaRA2.edges,
      })
    } else if (slug == 'otta-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range  Year-over-year percentage change',
        sixColTableData: this.props.data.OttaSP1.edges,
        sixColTableName2:
          'Summary – Sales by Price Range  Year-over-year percentage change',
        sixColTableData2: this.props.data.OttaSP2.edges,
        sixColTableName3:
          'Summary – Sales by Price Range  Year-over-year percentage change',
        sixColTableData3: this.props.data.OttaSP3.edges,
      })
    } else if (slug == 'otta-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'otta-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'otta-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'kawa-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.KawaRA1.edges,
      })
    } else if (slug == 'kawa-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.KawaSP1.edges,
      })
    } else if (slug == 'kaml-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableData: this.props.data.KamlRA1.edges,
        fourColTableData2: this.props.data.KamlRA2.edges,
        fourColTableName: 'Summary - Sales by Housing Type',
        fourColTableName2: 'Summary – Median Price by Housing Type',
      })
    } else if (slug == 'kaml-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableData: this.props.data.KamlSP1.edges,
        sixColTableData2: this.props.data.KamlSP2.edges,
        sixColTableData3: this.props.data.KamlSP3.edges,
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableName2:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableName3:
          'Summary – Sales by Price Range / Year-over-year percentage change',
      })
    } else if (slug == 'koot-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableData: this.props.data.KootRA1.edges,
        fourColTableData2: this.props.data.KootRA2.edges,
        fourColTableName: 'Summary - Sales by Housing Type',
        fourColTableName2: 'Summary – Median Price by Housing Type',
      })
    } else if (slug == 'koot-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableData: this.props.data.KootSP1.edges,
        sixColTableData2: this.props.data.KootSP2.edges,
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableName2:
          'Summary – Sales by Price Range / Year-over-year percentage change',
      })
    } else if (slug == 'kawa-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'kawa-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'kawa-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'corn-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price /Single Detached',
        fourColTableData: this.props.data.CornRA1.edges,
      })
    } else if (slug == 'corn-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.CornSP1.edges,
      })
    } else if (slug == 'corn-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'corn-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'corn-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'gran-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.GranRA1.edges,
      })
    } else if (slug == 'gran-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.GranSP1.edges,
      })
    } else if (slug == 'gran-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'gran-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'gran-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'grey-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.GreyRA1.edges,
      })
    } else if (slug == 'grey-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.GreySP1.edges,
      })
    } else if (slug == 'grey-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'grey-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'grey-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'oakv-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableData: this.props.data.OakvRA1.edges,
        fourColTableData2: this.props.data.OakvRA2.edges,
        fourColTableName: 'Summary – Sales by Housing Type',

        fourColTableName2: 'Summary – Median Price by Housing Type',
      })
    } else if (slug == 'oakv-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableData: this.props.data.OakvSP1.edges,
        sixColTableData2: this.props.data.OakvSP2.edges,
        sixColTableData3: this.props.data.OakvSP3.edges,
        sixColTableName:
          'Summary – Sales by Price Range  Year-over-year percentage change',

        sixColTableName2:
          'Summary – Sales by Price Range  Year-over-year percentage change',
        sixColTableName3:
          'Summary – Sales by Price Range  Year-over-year percentage change',
      })
    } else if (slug == 'oakv-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'oakv-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'oakv-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'okan-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales by Housing Type',
        fourColTableData: this.props.data.OkanRA1.edges,
        fourColTableName2: 'Summary – Median Price by Housing Type',
        fourColTableData2: this.props.data.OkanRA2.edges,
      })
    } else if (slug == 'okan-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range  Year-over-year percentage change',
        sixColTableData: this.props.data.OkanSPR1.edges,
        sixColTableName2:
          'Summary – Sales by Price Range  Year-over-year percentage change',
        sixColTableData2: this.props.data.OkanSPR2.edges,
        sixColTableName3:
          'Summary – Sales by Price Range  Year-over-year percentage change',
        sixColTableData3: this.props.data.OkanSPR3.edges,
      })
    } else if (slug == 'okan-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'okan-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'okan-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'renf-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales by Housing Type',
        fourColTableData: this.props.data.RenfRA1.edges,
      })
    } else if (slug == 'renf-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range  Year-over-year percentage change',
        sixColTableData: this.props.data.RenfSP1.edges,
      })
    } else if (slug == 'renf-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'renf-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'renf-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'stjo-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales by Housing Type',
        fourColTableData: this.props.data.StjoRA1.edges,
        fourColTableName2: 'Summary – Median Price by Housing Type',
        fourColTableData2: this.props.data.StjoRA2.edges,
      })
    } else if (slug == 'stjo-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range  Year-over-year percentage change',
        sixColTableData: this.props.data.StjoSP1.edges,
        sixColTableName2:
          'Summary – Sales by Price Range  Year-over-year percentage change',
        sixColTableData2: this.props.data.StjoSP2.edges,
      })
    } else if (slug == 'stjo-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'stjo-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'stjo-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'norl-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableData: this.props.data.NorlRA.edges,

        fourColTableName: 'Summary - Sales by Housing Type',
      })
    } else if (slug == 'norl-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableData: this.props.data.NorlSP.edges,

        sixColTableName:
          'Summary – Sales by Price Range  Year-over-year percentage change',
      })
    } else if (slug == 'nsar-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.NsarRA1.edges,
        fourColTableName2: 'Detailed – Single Detached Sales and Median Price by Area / Single Detached',
        fourColTableData2: this.props.data.NsarRA2.edges,
      })
    } else if (slug == 'nsar-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableData: this.props.data.NsarSP1.edges,
        sixColTableData2: this.props.data.NsarSP2.edges,
        sixColTableData3: this.props.data.NsarSP3.edges,
        sixColTableName:
          'Summary – Sales by Price Range  Year-over-year percentage change',
        sixColTableName2:
          'Summary – Sales by Price Range  Year-over-year percentage change',
        sixColTableName3:
          'Summary – Sales by Price Range  Year-over-year percentage change',
      })
    } else if (slug == 'nsar-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'nsar-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'nsar-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'till-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.TillRA1.edges,
      })
    } else if (slug == 'till-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.TillSP1.edges,
      })
    } else if (slug == 'till-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'till-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'till-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'peia-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.PeiaRA1.edges,
        sevenColTableName2: 'Detailed – Single Detached Sales and Median Price by Area / Single Detached',
        sevenColTableData2: this.props.data.PeiaRA2.edges,
      })
    } else if (slug == 'peia-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.PeiaSP1.edges,
      })
    } else if (slug == 'peia-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'peia-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'peia-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'quin-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableData: this.props.data.QuinRA1.edges,
        fourColTableData2: this.props.data.QuinRA2.edges,
        fourColTableName: 'Summary - Sales by Housing Type',
        fourColTableName2: 'Summary – Median Price by Housing Type',
      })
    } else if (slug == 'quin-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableData: this.props.data.QuinSP1.edges,
        sixColTableData2: this.props.data.QuinSP2.edges,
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableName2:
          'Summary – Sales by Price Range / Year-over-year percentage change',
      })
    } else if (slug == 'quin-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'quin-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'quin-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'sjnb-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.SjnbRA1.edges,
      })
    } else if (slug == 'sjnb-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.SjnbSP1.edges,
      })
    } else if (slug == 'sjnb-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'sjnb-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'sjnb-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'sarn-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.SarnRA1.edges,
      })
    } else if (slug == 'sarn-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.SarnSP1.edges,
      })
    } else if (slug == 'sarn-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'sarn-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'sarn-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'regi-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.RegiRA1.edges,
      })
    } else if (slug == 'regi-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.RegiSP1.edges,
      })
    } else if (slug == 'regi-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'regi-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'regi-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'sask-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'sask-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'sask-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'sore-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales by Housing Type',
        fourColTableData: this.props.data.SoreRA1.edges,
        fourColTableName2: 'Summary – Median Price by Housing Type',
        fourColTableData2: this.props.data.SoreRA2.edges,
      })
    } else if (slug == 'sore-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.SoreSP1.edges,
        sixColTableName2:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData2: this.props.data.SoreSP2.edges,
        sixColTableName3:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData3: this.props.data.SoreSP3.edges,
      })
    } else if (slug == 'sore-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'sore-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'sore-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'saul-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.SaulRA1.edges,
      })
    } else if (slug == 'saul-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.SaulSP1.edges,
      })
    } else if (slug == 'saul-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'saul-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'saul-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'wood-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.WoodRA1.edges,
      })
    } else if (slug == 'wood-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.WoodSP1.edges,
      })
    } else if (slug == 'wood-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'wood-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'wood-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'winn-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableData: this.props.data.WinnRA1.edges,
        fourColTableData2: this.props.data.WinnRA2.edges,
        fourColTableName: 'Summary - Sales by Housing Type',
        fourColTableName2: 'Summary – Median Price by Housing Type',
      })
    } else if (slug == 'winn-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableData: this.props.data.WinnSP1.edges,
        sixColTableData2: this.props.data.WinnSP2.edges,
        sixColTableData3: this.props.data.WinnSP3.edges,
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableName2:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableName3:
          'Summary – Sales by Price Range / Year-over-year percentage change',
      })
    } else if (slug == 'winn-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'winn-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'winn-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'vict-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableData: this.props.data.VictRA1.edges,
        fourColTableData2: this.props.data.VictRA2.edges,
        fourColTableName: 'Summary - Sales by Housing Type',
        fourColTableName2: 'Summary – Median Price by Housing Type',
      })
    } else if (slug == 'vict-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableData: this.props.data.VictSP1.edges,
        sixColTableData2: this.props.data.VictSP2.edges,
        sixColTableData3: this.props.data.VictSP3.edges,
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',

        sixColTableName2:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableName3:
          'Summary – Sales by Price Range / Year-over-year percentage change',
      })
    } else if (slug == 'vict-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'vict-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'vict-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'wind-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableData: this.props.data.WindRA1.edges,
        fourColTableData2: this.props.data.WindRA2.edges,
        fourColTableName: 'Summary - Sales by Housing Type',
        fourColTableName2: 'Summary – Median Price by Housing Type',
      })
    } else if (slug == 'wind-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableData: this.props.data.WindSP1.edges,
        sixColTableData2: this.props.data.WindSP2.edges,

        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableName2:
          'Summary – Sales by Price Range / Year-over-year percentage change',
      })
    } else if (slug == 'wind-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'wind-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'wind-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'sudb-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.SudbRA1.edges,
      })
    } else if (slug == 'sudb-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.SudbSP1.edges,
      })
    } else if (slug == 'sudb-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'sudb-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'sudb-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'thun-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.ThunRA1.edges,
      })
    } else if (slug == 'thun-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.ThunSP1.edges,
      })
    } else if (slug == 'thun-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'thun-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'thun-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'lond-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableData: this.props.data.LondRA1.edges,
        fourColTableData2: this.props.data.LondRA2.edges,
        fourColTableName: 'Summary - Sales by Housing Type',
        fourColTableName2: 'Summary – Median Price by Housing Type',
      })
    } else if (slug == 'lond-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableData: this.props.data.LondSP1.edges,
        sixColTableData2: this.props.data.LondSP2.edges,
        sixColTableData3: this.props.data.LondSP3.edges,
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableName2:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableName3:
          'Summary – Sales by Price Range / Year-over-year percentage change',
      })
    } else if (slug == 'lond-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'lond-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'lond-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'banc-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.BancRA1.edges,
      })
    } else if (slug == 'banc-sales-by-price-range') {
      // No table at home page
      this.setState({
        // chatRaChart: this.props.data.LondSalesByPriceRange.edges,

        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.BancSP1.edges,
      })
    } else if (slug == 'banc-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'banc-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'banc-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'calg-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.CalgRA1.edges,
        fourColTableName2: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData2: this.props.data.CalgRA2.edges,
      })
    } else if (slug == 'calg-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.CalgSP1.edges,
        sixColTableName2:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData2: this.props.data.CalgSP2.edges,
        sixColTableName3:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData3: this.props.data.CalgSP3.edges,
      })
    } else if (slug == 'calg-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'calg-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'calg-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'calg-sales-by-price-range') {
      // No table at home page
      this.setState({
        // chatRaChart: this.props.data.LondSalesByPriceRange.edges,
      })
    } else if (slug == 'calg-sales-by-category') {
      // No table at home page
      this.setState({
        // chatRaChart: this.props.data.LondSalesByCat.edges,
      })
    } else if (slug == 'calg-median-price') {
      // No table at home page
      this.setState({
        // chatRaChart: this.props.data.LondMedianPrice.edges,
      })
    } else if (slug == 'calg-market-conditions') {
      // No table at home page
      this.setState({
        // chatRaChart: this.props.data.LondMarketConditions.edges,
      })
    } else if (slug == 'guel-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales by Housing Type',
        fourColTableData: this.props.data.GuelRA1.edges,
        fourColTableName2: 'Summary – Median Price by Housing Type',
        fourColTableData2: this.props.data.GuelRA2.edges,
      })
    } else if (slug == 'guel-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableData: this.props.data.GuelSP1.edges,
        sixColTableData2: this.props.data.GuelSP2.edges,
        sixColTableData3: this.props.data.GuelSP3.edges,
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableName2:
          'Summary – Sales by Price Range / Year-over-year percentage change',

        sixColTableName3:
          'Summary – Sales by Price Range / Year-over-year percentage change',
      })
    } else if (slug == 'guel-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'guel-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'guel-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'wrar-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales by Housing Type',
        fourColTableData: this.props.data.KitcRA1.edges,
        fourColTableName2: 'Summary – Median Price by Housing Type',
        fourColTableData2: this.props.data.KitcRA2.edges,
      })
    } else if (slug == 'wrar-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableData: this.props.data.KitcSP1.edges,
        sixColTableData2: this.props.data.KitcSP2.edges,
        sixColTableData3: this.props.data.KitcSP3.edges,
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',

        sixColTableName2:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableName3:
          'Summary – Sales by Price Range / Year-over-year percentage change',
      })
    } else if (slug == 'kitc-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'kitc-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'kitc-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'noba-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.NobaRA1.edges,
      })
    } else if (slug == 'noba-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.NobaSP1.edges,
      })
    } else if (slug == 'noba-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'noba-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'noba-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'powe-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.PoweRA.edges,
      })
    } else if (slug == 'powe-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.PoweSP1.edges,
      })
    } else if (slug == 'powe-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'powe-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'powe-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'nbrea-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price',
        fourColTableData: this.props.data.NbreaRA1.edges,
      })
    } else if (slug == 'nbrea-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.NbreaSP1.edges,
      })
    } else if (slug == 'nonb-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price',
        fourColTableData: this.props.data.NonbRA1.edges,
      })
    } else if (slug == 'nonb-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.NonbSP1.edges,
      })
    } else if (slug == 'nonb-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'nonb-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'nonb-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'cobo-residential-activity') {
      // No table at home page
      this.setState({
        sevenColTableName: 'Summary – Sales and Median Price / Single Detached',
        sevenColTableData: this.props.data.CoboRA1.edges,
      })
    } else if (slug == 'cobo-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.CoboSP1.edges,
      })
    } else if (slug == 'cobo-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'cobo-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'cobo-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'pete-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.PeteRA1.edges,
      })
    } else if (slug == 'pete-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.PeteSP1.edges,
      })
    } else if (slug == 'pete-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'pete-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'pete-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'cent-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.CentRA1.edges,
      })
    } else if (slug == 'cent-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.CentSP1.edges,
      })
    } else if (slug == 'cent-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'cent-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'cent-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'simc-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.SimcRA1.edges,
      })
    } else if (slug == 'simc-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.SimcSP1.edges,
      })
    } else if (slug == 'simc-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'simc-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'simc-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'timm-residential-activity') {
      // No table at home page
      this.setState({
        // chatRaChart: this.props.data.LondRaChart.edges,
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.TimmRA1.edges,
      })
    } else if (slug == 'timm-sales-by-price-range') {
      // No table at home page
      this.setState({
        // chatRaChart: this.props.data.LondSalesByPriceRange.edges,
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.TimmSP1.edges,
      })
    } else if (slug == 'timm-sales-by-category') {
      // No table at home page
      this.setState({
        // chatRaChart: this.props.data.LondSalesByCat.edges,
      })
    } else if (slug == 'timm-median-price') {
      // No table at home page
      this.setState({
        // chatRaChart: this.props.data.LondMedianPrice.edges,
      })
    } else if (slug == 'timm-market-conditions') {
      // No table at home page
      this.setState({
        // chatRaChart: this.props.data.LondMarketConditions.edges,
      })
    } else if (slug == 'vani-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableData: this.props.data.VaniRA1.edges,
        fourColTableData2: this.props.data.VaniRA2.edges,
        fourColTableName: 'Summary - Sales by Housing Type',
        fourColTableName2: 'Summary – Median Price by Housing Type',
      })
    } else if (slug == 'vani-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableData: this.props.data.VaniSP1.edges,
        sixColTableData2: this.props.data.VaniSP2.edges,
        sixColTableData3: this.props.data.VaniSP3.edges,
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableName2:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableName3:
          'Summary – Sales by Price Range / Year-over-year percentage change',
      })
    } else if (slug == 'vani-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'vani-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'vani-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'fred-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.FredRA1.edges,
      })
    } else if (slug == 'fred-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.FredSP1.edges,
      })
    } else if (slug == 'fred-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'fred-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'fred-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'fort-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary – Sales and Median Price / Single Detached',
        fourColTableData: this.props.data.FortRA1.edges,
      })
    } else if (slug == 'fort-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.FortSP1.edges,
      })
    } else if (slug == 'fort-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'fort-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'fort-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'osha-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableName: 'Summary - Sales by Housing Type',
        fourColTableData: this.props.data.OshaRA1.edges,
        fourColTableName2: 'Summary – Median Price by Housing Type',
        fourColTableData2: this.props.data.OshaRA2.edges,
      })
    } else if (slug == 'osha-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.OshaSP1.edges,
        sixColTableName2:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData2: this.props.data.OshaSP2.edges,
        sixColTableName3:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData3: this.props.data.OshaSP3.edges,
        sixColTableName4:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData4: this.props.data.OshaSP4.edges,
      })
    } else if (slug == 'osha-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'redd-residential-activity') {
      // No table at home page
      this.setState({
        fourColTableData: this.props.data.ReddRA1.edges,
        fourColTableData2: this.props.data.ReddRA2.edges,
        fourColTableName: 'Summary - Sales by Housing Type',
        fourColTableName2: 'Summary – Median Price by Housing Type',
      })
    } else if (slug == 'redd-sales-by-price-range') {
      // No table at home page
      this.setState({
        sixColTableData: this.props.data.ReddSP1.edges,
        sixColTableData2: this.props.data.ReddSP2.edges,
        sixColTableData3: this.props.data.ReddSP3.edges,
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.ReddSP1.edges,
        sixColTableName2:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.ReddSP1.edges,
        sixColTableName3:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableData: this.props.data.ReddSP1.edges,
      })
    } else if (slug == 'redd-sales-by-category') {
      // No table at home page
      this.setState({})
    } else if (slug == 'redd-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'redd-market-conditions') {
      // No table at home page
      this.setState({})
    } else if (slug == 'monc-residential-activity') {
      this.setState({
        fourColTableData: this.props.data.MoncRA1.edges,
        fourColTableData2: this.props.data.MoncRA2.edges,
        fourColTableName: 'Summary – Sales ',
        fourColTableName2: 'Summary – Median Price ',
      })
    } else if (slug == 'monc-sales-by-price-range') {
      this.setState({
        sixColTableData: this.props.data.MoncSPR1.edges,
        sixColTableData2: this.props.data.MoncSPR2.edges,
        sixColTableName:
          'Summary – Sales by Price Range / Year-over-year percentage change',
        sixColTableName2:
          'Summary – Sales by Price Range / Year-over-year percentage change',
      })
    } else if (slug == 'osha-median-price') {
      // No table at home page
      this.setState({})
    } else if (slug == 'osha-market-conditions') {
      // No table at home page
      this.setState({})
    } else {
      this.setState({
        data: [],
        data1: [],
        data2: [],
        data3: [],
        chatRaChart: [],
        chatRaChart2: [],
        chatRaChart3: [],
        chatRaChart4: [],

        charts: [],
        fourColTableData: [],
        fourColTableData2: [],
      })
    }

    let chartOne = this.props.data.contentfulMlsPages.mlsChartsOfA
    let chartTwo = this.props.data.contentfulMlsPages.mlsChartsOfB
    let chartThree = this.props.data.contentfulMlsPages.mlsChartsOfC
    let chartFour = this.props.data.contentfulMlsPages.mlsChartsOfD

    chartOne = chartOne
      ? Object.entries(chartOne).map(e => ({ image: e[1] }))
      : []
    chartTwo = chartTwo
      ? Object.entries(chartTwo).map(e => ({ image: e[1] }))
      : []
    chartThree = chartThree
      ? Object.entries(chartThree).map(e => ({ image: e[1] }))
      : []
    chartFour = chartFour
      ? Object.entries(chartFour).map(e => ({ image: e[1] }))
      : []
    this.setState({
      mlsChart: chartOne,
      mlsChartB: chartTwo,
      mlsChartC: chartThree,
      mlsChartD: chartFour,
    })
  }

  render() {
    const mls = get(this.props, 'data.contentfulMlsPages')
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    console.log(this.props, 'props')
    const {
      data,
      data2,
      data3,
      data4,
      chatRaChart,
      chatRaChart2,
      chatRaChart3,
      chatRaChart4,

      field1,
      field2,
      field3,
      field4,
      name1,
      name2,
      name3,
      name4,
      charts,
      fourColTableData,
      fourColTableName,
      fourColTableData2,
      fourColTableName2,
      sevenColTableName,
      sevenColTableData,
      sevenColTableName2,
      sevenColTableData2,
      sevenColTableName3,
      sevenColTableData3,
      sevenColTableName4,
      sevenColTableData4,
      sixColTableName,
      sixColTableData,
      sixColTableName2,
      sixColTableData2,
      sixColTableName3,
      sixColTableData3,
      sixColTableName4,
      sixColTableData4,
      mlsChart,
      mlsChartB,
      mlsChartC,
      mlsChartD,
    } = this.state

    return (
      <Layout
        data={this.props.data}
        mls={mls}
        board={true}
        name1={name1}
        name2={name2}
        name3={name3}
        name4={name4}
        field1={field1}
        field2={field2}
        field3={field3}
        field4={field4}
        tableData={data}
        tableData2={data2}
        tableData3={data3}
        tableData4={data4}
        chatRaChart={chatRaChart}
        chatRaChart2={chatRaChart2}
        chatRaChart3={chatRaChart3}
        chatRaChart4={chatRaChart4}
        mlsChart={mlsChart}
        mlsChartB={mlsChartB}
        mlsChartC={mlsChartC}
        mlsChartD={mlsChartD}
        chartsData={charts}
        fourColTableData={fourColTableData}
        fourColTableName={fourColTableName}
        fourColTableData2={fourColTableData2}
        fourColTableName2={fourColTableName2}
        sevenColTableName={sevenColTableName}
        sevenColTableData={sevenColTableData}
        sevenColTableName2={sevenColTableName2}
        sevenColTableData2={sevenColTableData2}
        sevenColTableName3={sevenColTableName3}
        sevenColTableData3={sevenColTableData3}
        sevenColTableName4={sevenColTableName4}
        sevenColTableData4={sevenColTableData4}
        sixColTableName={sixColTableName}
        sixColTableData={sixColTableData}
        sixColTableName2={sixColTableName2}
        sixColTableData2={sixColTableData2}
        sixColTableName3={sixColTableName3}
        sixColTableData3={sixColTableData3}
        sixColTableName4={sixColTableName4}
        sixColTableData4={sixColTableData4}
      >
        <GlobalStyle />
      </Layout>
    )
  }
}

MlsTemplate.propTypes = propTypes

export default MlsTemplate

export const pageQuery = graphql`
  query MlsPostBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }

    contentfulMlsPages(id: { eq: $id }) {
      id
      title
      slug
      boardName
      MlsboardPageName

      mainContent {
        childContentfulRichText {
          html
        }
      }
      mainContent2 {
        childContentfulRichText {
          html
        }
      }
      mainContent3 {
        childContentfulRichText {
          html
        }
      }
      mainContent4 {
        childContentfulRichText {
          html
        }
      }
      additionalContent {
        childMarkdownRemark {
          html
        }
      }
      menuCompile {
        title
        boardSubMenu {
          title
          slug
        }
        boardsSubRef {
          title
          slug
        }
        boardMlsMenu {
          title
          slug
        }
      }
      mlsChartsOfA {
        image1
        image2
        image3
        image4
        image5
        image6
        image7
        image8
        image9
        image10
      }
      mlsChartsOfB {
        image1
        image2
        image3
        image4
        image5
        image6
        image7
        image8
        image9
        image10
      }
      mlsChartsOfC {
        image1
        image2
        image3
        image4
        image5
        image6
        image7
        image8
        image9
        image10
      }
      mlsChartsOfD {
        image1
        image2
        image3
        image4
        image5
        image6
        image7
        image8
        image9
        image10
      }
    }
    TrebRA: allTrebTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    TrebRA2: allTrebTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    TrebSPR: allTrebTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    TrebSPR2: allTrebTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    TrebSPR3: allTrebTable05Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    TrebSPR4: allTrebTable06Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    EdmoRA: allEdmoTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    EdmoRA2: allEdmoTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    EdmoSPR: allEdmoTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    EdmoSPR2: allEdmoTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    EdmoSPR3: allEdmoTable05Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }

    MuskRA1: allMuskTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    MuskRA2: allMuskTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    MuskRA3: allMuskTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
          field_7
        }
      }
    }
    MuskRA4: allMuskTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
          field_7
        }
      }
    }
    MuskSPR: allMuskTable05Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    MuskSPR1: allMuskTable06Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    BarrRA: allBarrTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    BarrRA2: allBarrTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    BarrSPR: allBarrTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    BarrSPR2: allBarrTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    BarrSPR3: allBarrTable05Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }

    ChatRA: allChatTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    ChatSPR: allChatTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    CariRA1: allCariTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    CariRA2: allCariTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
          field_7
        }
      }
    }
    CariSP1: allCariTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    CollSPR: allCollTable05Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    CollSPR2: allCollTable06Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    BancRA1: allBancTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    BancSP1: allBancTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    BrndRA1: allBrndTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    BrndSP1: allBrndTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    BrntRA1: allBrntTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    BrntRA2: allBrntTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    BrntSP1: allBrntTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    BrntSP2: allBrntTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    CalgRA1: allCalgTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    CalgRA2: allCalgTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    CalgSP1: allCalgTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    CalgSP2: allCalgTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    CalgSP3: allCalgTable05Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    CambRA1: allCambTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    CambRA2: allCambTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    CambSP1: allCambTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    CambSP2: allCambTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    ReddRA1: allReddTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    ReddRA2: allReddTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    ReddSP1: allReddTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    ReddSP2: allReddTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    ReddSP3: allReddTable05Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    ChilRA1: allChilTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    ChilRA2: allChilTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    ChilSP1: allChilTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    ChilSP2: allChilTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    ChilSP3: allChilTable05Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    CornRA1: allCornTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    CornSP1: allCornTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    GranRA1: allGranTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    GranSP1: allGranTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    GreyRA1: allGreyTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    GreySP1: allGreyTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    GuelRA1: allGuelTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    GuelRA2: allGuelTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    GuelSP1: allGuelTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    GuelSP2: allGuelTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    GuelSP3: allGuelTable05Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    HamiRA1: allHamiTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    HamiRA2: allHamiTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    HamiSP1: allHamiTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    HamiSP2: allHamiTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    HamiSP3: allHamiTable05Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    HuroRA1: allHuroTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    HuroSP1: allHuroTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    KamlRA1: allKamlTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    KamlRA2: allKamlTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    KamlSP1: allKamlTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    KamlSP2: allKamlTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    KamlSP3: allKamlTable05Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    KootRA1: allKootTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    KootRA2: allKootTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    KootSP1: allKootTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    KootSP2: allKootTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    KawaRA1: allKawaTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    KawaSP1: allKawaTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    KingRA1: allKingTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    KingSP1: allKingTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    KitcRA1: allKitcTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    KitcRA2: allKitcTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    KitcSP1: allKitcTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    KitcSP2: allKitcTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    KitcSP3: allKitcTable05Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    LethRA1: allLethTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    LethSP1: allLethTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    LondRA1: allLondTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    LondRA2: allLondTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    LondSP1: allLondTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    LondSP2: allLondTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    LondSP3: allLondTable05Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    MediRA1: allMediTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    MediSP1: allMediTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    MissRA1: allMissTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    MissRA2: allMissTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    MissSP1: allMissTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    MissSP2: allMissTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    MissSP3: allMissTable05Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    MissSP4: allMissTable06Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    NorlRA: allNorlTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    NorlSP: allNorlTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    StjoRA1: allStjoTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    StjoRA2: allStjoTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    StjoSP1: allStjoTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    StjoSP2: allStjoTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    StcaRA1: allStcaTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    StcaRA2: allStcaTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    StcaSP1: allStcaTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    StcaSP2: allStcaTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    StcaSP3: allStcaTable05Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    NobaRA1: allNobaTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    NobaSP1: allNobaTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    PoweRA: allPoweTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    PoweSP1: allPoweTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    NonbRA1: allNonbTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    NonbSP1: allNonbTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    NbreaRA1: allNbreaTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    NbreaSP1: allNbreaTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }

    CoboRA1: allCoboTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
          field_7
        }
      }
    }
    CoboSP1: allCoboTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    NsarRA1: allNsarTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    NsarRA2: allNsarTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    NsarSP1: allNsarTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    NsarSP2: allNsarTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    NsarSP3: allNsarTable05Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    OakvRA1: allOakvTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    OakvRA2: allOakvTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    OakvSP1: allOakvTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    OakvSP2: allOakvTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    OakvSP3: allOakvTable05Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    OttaRA1: allOttaTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    OttaRA2: allOttaTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    OttaSP1: allOttaTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    OttaSP2: allOttaTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    OttaSP3: allOttaTable05Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    PeteRA1: allPeteTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    PeteSP1: allPeteTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    PeiaRA1: allPeiaTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    PeiaRA2: allPeiaTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
          field_7
        }
      }
    }
    PeiaSP1: allPeiaTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    QuinRA1: allQuinTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    QuinRA2: allQuinTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    QuinSP1: allQuinTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    QuinSP2: allQuinTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    CentRA1: allCentTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    CentSP1: allCentTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }

    RenfRA1: allRenfTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    RenfSP1: allRenfTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    SjnbRA1: allSjnbTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    SjnbSP1: allSjnbTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    SarnRA1: allSarnTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    SarnSP1: allSarnTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }

    SimcRA1: allSimcTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    SimcSP1: allSimcTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    BrooRA1: allBrooTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    BrooSP1: allBrooTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    SaulRA1: allSaulTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    SaulSP1: allSaulTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    SoreRA1: allSoreTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    SoreRA2: allSoreTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    SoreSP1: allSoreTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    SoreSP2: allSoreTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    SoreSP3: allSoreTable05Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    CollRA1: allCollTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
          field_7
        }
      }
    }
    CollRA2: allCollTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
          field_7
        }
      }
    }
    CollRA3: allCollTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
          field_7
        }
      }
    }
    CollRA4: allCollTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
          field_7
        }
      }
    }
    CollSP1: allCollTable05Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    CollSP2: allCollTable06Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    SudbRA1: allSudbTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    SudbSP1: allSudbTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    ThunRA1: allThunTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    ThunSP1: allThunTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    TillRA1: allTillTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    TillSP1: allTillTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    TimmRA1: allTimmTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    TimmSP1: allTimmTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    VictRA1: allVictTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    VictRA2: allVictTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    VictSP1: allVictTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    VictSP2: allVictTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    VictSP3: allVictTable05Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    WindRA1: allWindTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    WindRA2: allWindTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    WindSP1: allWindTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    WindSP2: allWindTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }

    WinnRA1: allWinnTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    WinnRA2: allWinnTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    WinnSP1: allWinnTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    WinnSP2: allWinnTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    WinnSP3: allWinnTable05Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    WoodRA1: allWoodTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    WoodSP1: allWoodTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    VaniRA1: allVaniTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    VaniRA2: allVaniTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    VaniSP1: allVaniTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    VaniSP2: allVaniTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    VaniSP3: allVaniTable05Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    OshaRA1: allOshaTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    OshaRA2: allOshaTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    OshaSP1: allOshaTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    OshaSP2: allOshaTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    OshaSP3: allOshaTable05Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    OshaSP4: allOshaTable06Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    FortRA1: allFortTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    FortSP1: allFortTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    FredRA1: allFredTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    FredSP1: allFredTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    OkanRA1: allOkanTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    OkanRA2: allOkanTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    OkanSPR1: allOkanTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    OkanSPR2: allOkanTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    OkanSPR3: allOkanTable05Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    SraRA: allSraTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    SraRA2: allSraTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    SraSPR: allSraTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    SraSPR2: allSraTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }

    MoncRA1: allMoncTable01Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    MoncRA2: allMoncTable02Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
        }
      }
    }
    MoncSPR1: allMoncTable03Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
    MoncSPR2: allMoncTable04Csv {
      edges {
        node {
          field_1
          field_2
          field_3
          field_4
          field_5
          field_6
        }
      }
    }
  }
`
